import React, { useState } from "react";
import { SCol } from "@avalara/skylab-react";
import Upgrade from "../upgradeTier/OnlineBuyDialog";
import FVSUploader from "../sharedComponents/FVSUploader";
import FeatureToggler from "../../featureToggler/FeatureToggler";
import Upsell from "../sharedComponents/Upsell";

function CertificateFileNotFound(props) {
    const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);

    let uploadElm = (
        <FVSUploader
            certificateID={props?.certificateID}
            isSupplementalAttachment={false}
            showOnlyComponent
            displayOn="certificateDetails"
            isUploaderDisabled={props?.isUploaderDisabled}
            showRevokedAlert={props?.showRevokedAlert}
        />
    );

    if (props.isOutgoing) {
        uploadElm = <h1 className="margin-left-lg"> Image not found</h1>;
    }

    return (
        <>
            <SCol span="auto">
                <FeatureToggler
                    category="validatedCertificate"
                    id="upload"
                    firstChild={uploadElm}
                    secondChild={<Upsell onClick={() => setIsUpgradeDialogOpen(true)} />}
                />
            </SCol>
            <Upgrade
                isUpgradeDialogOpen={isUpgradeDialogOpen}
                setIsUpgradeDialogOpen={setIsUpgradeDialogOpen}
                type="request"
            />
        </>
    );
}

export default CertificateFileNotFound;
