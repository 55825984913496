import axios from "../../axios";
import { buildApiV3Url, removeSpaces } from "../../shared/Utils";
import { documentTypeNames } from "../../shared/constants";
import {
    setCustomFields,
    setIsLoadingPendingCertificateSelectedColumns,
    setPendingCertificateSelectedColumns,
} from "./index";

const getPendingCertificateColumnList =
    isEligibleUserCustomFields => async (dispatch, getState) => {
        const { pendingCertificateSelectedColumns, isLoadingPendingCertificateSelectedColumns } =
            getState().certificate;

        if (isLoadingPendingCertificateSelectedColumns) {
            return false;
        }

        dispatch(setIsLoadingPendingCertificateSelectedColumns(true));
        let config = pendingCertificateSelectedColumns;

        const preferenceResponse = await axios.get(buildApiV3Url(`ecm-preferences`), {
            withCredentials: true,
        });

        if (preferenceResponse?.data?.value) {
            const pendingCertificatePreference = preferenceResponse.data.value.filter(
                pref => pref.preference === "pending-certificate-columns"
            );
            if (
                pendingCertificatePreference.length !== 0 &&
                pendingCertificatePreference[0].preferenceValue
            ) {
                config = JSON.parse(pendingCertificatePreference[0].preferenceValue);
            }
        }

        const originalFileColumnItem = {
            id: "orig-file-name",
            label: "File name",
            hidden: false,
        };

        // If Original File Column is already present then override its label value with new one
        config = [
            ...config.map(column => {
                if (column.id === originalFileColumnItem.id) {
                    return { ...column, label: originalFileColumnItem.label };
                }
                return column;
            }),
        ];

        if (isEligibleUserCustomFields) {
            config = await processCustomFields(config, dispatch);
        }

        const updatedConfig = config.filter(x => x.id !== "certificate-file-name");
        dispatch(setPendingCertificateSelectedColumns(updatedConfig));
        dispatch(setIsLoadingPendingCertificateSelectedColumns(false));
        return true;
    };

const processCustomFields = async (config, dispatch) => {
    const certificateCustomFields = await fetchCustomFields();

    if (!certificateCustomFields.length) {
        return config;
    }

    dispatch(setCustomFields(certificateCustomFields));

    return updateConfigWithCustomFields(config, certificateCustomFields);
};

const fetchCustomFields = async () => {
    const response = await axios.get(
        buildApiV3Url(
            `certificate-custom-fields?$filter=documentType.Name eq '${documentTypeNames.salesAndUseTax}' and documentType.Outgoing eq false&$top=100`
        ),
        {
            withCredentials: true,
        }
    );

    return response?.data?.value || [];
};

const updateConfigWithCustomFields = (config, certificateCustomFields) => {
    config = filterExistingCustomFields(config, certificateCustomFields);

    const formatCustomFields = certificateCustomFields.map(field => ({
        id: removeSpaces(field.fieldName),
        label: field.fieldName,
        hidden: true,
        fieldName: field.fieldName,
    }));

    return mergeCustomFields(config, formatCustomFields);
};

const filterExistingCustomFields = (config, certificateCustomFields) => {
    return config.filter(
        column =>
            !column.fieldName ||
            (column.fieldName &&
                certificateCustomFields.some(col => col.fieldName === column.fieldName))
    );
};

const mergeCustomFields = (config, formatCustomFields) => {
    // Add missing custom fields
    formatCustomFields.forEach(customField => {
        if (!config.find(column => column.id === customField.id)) {
            config.push(customField);
        }
    });

    // Update existing fields with current data
    return config.map(column => {
        const matchingData = formatCustomFields.find(data => data.id === column.id);
        return matchingData ? { ...column, fieldName: matchingData.fieldName } : column;
    });
};

export default getPendingCertificateColumnList;
