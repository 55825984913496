import React from "react";
import { SBox } from "@avalara/skylab-react";

function Upsell(props) {
    return (
        <SBox className="margin-top-md text-center pad-bottom-xl">
            <h3 className="custom-line-height">Subscribe to ECM</h3>
            <p>
                You need to subscribe to ECM in order to attach certificate images and be ready for
                an audit.
            </p>
            <button className="secondary margin-bottom-md" onClick={props.onClick}>
                Learn more and buy
            </button>
        </SBox>
    );
}

export default Upsell;
